import styled, { css } from 'styled-components';
import { ChevronProps, ProfileDropdownSectionProps } from './props';
import { StaggerAnimation } from '@package/components';

export const Container = styled.div<
  Pick<ProfileDropdownSectionProps, 'direction'>
>`
  width: ${({ direction }) => (direction == 'column' ? '100%' : 'max-content')};
  padding: 16px 24px;

  @media (max-width: 768px) {
    padding: 8px 12px;
  }
`;

export const CollapseWrapper = styled(StaggerAnimation)<
  Pick<ProfileDropdownSectionProps, 'direction'>
>`
  width: 100%;
  display: flex;
  flex-direction: ${({ direction }) => direction};

  ${({ direction }) =>
    direction == 'row' &&
    css`
      width: max-content;
      display: flex;
      align-items: center;
      gap: 8px;
    `}
`;

export const SectionTitle = styled.div`
  display: flex;
  text-transform: uppercase;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.vars.palette.text.primary};
  cursor: pointer;
  transition: color 0.25s ease-in-out;
  font-size: 10px;
  font-weight: 500;

  &:hover {
    color: ${({ theme }) => theme.vars.palette.text.primary};
  }
`;

export const Chevron = styled.i<ChevronProps>`
  font-size: 10px;
  transform: rotateX(${({ open }) => (open ? '180deg' : '0deg')});
  transition: ease 0.3s;
  padding: 4px;
`;
