import styled, { css } from 'styled-components';
import Color from 'color';
import { ContainerProps, EmojiCircleProps, ItemProps } from './props';
import { Image } from '@package/components';
import { getComputedColor } from '@/utils/theme';

export const Container = styled.div<ContainerProps>`
  margin: 0;
  width: 300px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.vars.palette.background.paper};
  gap: 8px;
  padding: 16px 0 0;

  ${({ isAuthorized }) =>
    !isAuthorized &&
    css`
      & > div:first-of-type {
        border-top: none;
      }
    `}
`;

export const Circle = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.vars.palette.background.default};
  overflow: hidden;
  flex-shrink: 0 !important;

  & .user-image {
    width: 100%;
    height: 100%;
  }
`;

export const Item = styled.div<ItemProps>`
  width: 100%;
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  color: ${({ theme }) => theme.vars.palette.text.primary};
  background-color: ${({ theme, current }) =>
    current
      ? Color(getComputedColor(theme.vars.palette.background.paper))
          .lighten(0.2)
          .toString()
      : 'transparent'};
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.vars.shape.borderRadius};
  transition: color 0.25s ease-in-out;

  ${({ direction }) =>
    direction == 'row' &&
    css`
      width: max-content !important;
    `}

  & img,
  & i {
    margin-right: 8px;
    font-size: 15px;
    width: 18px;
    transform: ${({ custom }) => (custom ? 'rotate(90deg)' : '')};
  }

  &:hover {
    @media (min-width: 768px) {
      color: ${({ theme }) =>
        Color(getComputedColor(theme.vars.palette.primary.main))
          .alpha(0.8)
          .toString()};
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.8;
    `}
`;

export const Chip = styled.div`
  font-size: 13px;
  line-height: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.vars.palette.primary.main};
  background-color: ${({ theme }) =>
    Color(getComputedColor(theme.vars.palette.primary.main))
      .alpha(0.2)
      .toString()};
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  border-radius: ${({ theme }) => theme.vars.shape.borderRadius};
  padding-bottom: 8px;
`;

export const SmallSpacer = styled.div`
  margin-top: 8px;
`;

export const Subtitle = styled.div`
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.vars.palette.text.primary};
  margin-bottom: 10px;
`;

export const EmojiCircle = styled.div<EmojiCircleProps>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  border: 1px solid
    ${({ theme }) =>
      Color(getComputedColor(theme.vars.palette.text.secondary))
        .alpha(0.15)
        .toString()};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;

  &:hover {
    color: ${({ theme }) =>
      Color(getComputedColor(theme.vars.palette.text.primary))
        .alpha(0.8)
        .toString()};
  }
`;

export const LogoutWrapper = styled.div`
  width: 100%;
`;

export const PhotoContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) =>
    Color(getComputedColor(theme.vars.palette.background.default))
      .lighten(0.5)
      .toString()};
`;

export const Photo = styled(Image)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;
