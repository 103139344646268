import { CollapseWrapper, Container } from './styles';
import useTranslation from 'next-translate/useTranslation';
import { ProfileDropdownSectionProps } from './props';
import { Item, LogoutWrapper } from '../styles';
import Link from '@/components/core/Link';
import { useLogout } from '@/api/iam';
import { PageProps } from '@/types/page/props';
import { useUserAccount } from '@/api/user/queries';
import { usePathname } from '@/hooks/usePathname';

export default function ProfileDropdownSection({
  section,
  onClick,
  direction = 'column',
}: ProfileDropdownSectionProps) {
  const { t, lang } = useTranslation('common');
  const { isAuthorized } = useUserAccount();
  const { mutate, isLoading } = useLogout();
  const pathname: string = usePathname();

  const handleDisconnect = () => {
    mutate();
  };

  function handleClick(page: PageProps) {
    if (typeof page.clickHandler == 'function') {
      page.clickHandler();
    }

    if (typeof onClick == 'function') {
      onClick();
    }
  }

  return (
    <Container direction={direction}>
      <CollapseWrapper
        stagger={0.05}
        staggerDirection="up"
        direction={direction}
      >
        {section.pages.map((page, i) => (
          <Link
            key={`profile_section_page_item_${i}`}
            privateRoute={page.isProtected}
            href={page.paths[0]}
            modal={page.modal}
            onClick={() => handleClick(page)}
          >
            <Item
              current={page.paths.some(p =>
                p == '/'
                  ? p == pathname.replace(lang, '')
                  : pathname.replace(lang, '').includes(p),
              )}
              direction={direction}
            >
              <i
                className={`${page.icon} icon`}
                style={{
                  color: page?.iconColor ? page?.iconColor : undefined,
                }}
              />
              <span className="label">{t(page.label)}</span>
            </Item>
          </Link>
        ))}
        {isAuthorized && direction != 'row' && (
          <LogoutWrapper>
            <Item
              onClick={handleDisconnect}
              current={false}
              direction={direction}
              disabled={isLoading}
            >
              <i
                className={
                  isLoading
                    ? 'fas fa-circle-notch fa-spin'
                    : 'fa-solid fa-right-from-bracket'
                }
              />
              {t('logout_button')}
            </Item>
          </LogoutWrapper>
        )}
      </CollapseWrapper>
    </Container>
  );
}
