import ProfileDropdownSection from '@/components/ProfileDropdown/Section';
import styles from './styles.module.scss';
import { navigationSection } from '@/constants/sections/navigation';
import { useDetectVerticalScroll } from '@starsoft/common/hooks';
import { useMemo } from 'react';
import games from '@/constants/games';
import { useShowNavigation } from '@/hooks/useShowNavigation';
import { usePathname } from '@/hooks/usePathname';

export default function LayoutMobileNavigation() {
  const { hasScrolled } = useDetectVerticalScroll();
  const pathname: string = usePathname();

  const isGamePage = useMemo(
    () => Array.from(games.values()).some(g => pathname.includes(g.path)),
    [pathname],
  );

  const showNavigation = useShowNavigation();

  if (!showNavigation) {
    return null;
  }

  return (
    <div
      className={`${styles.container}  ${hasScrolled || isGamePage ? styles['container--hasScrolled'] : ''}`}
    >
      <div className={`${styles.container__content}`}>
        <ProfileDropdownSection section={navigationSection} direction="row" />
      </div>
    </div>
  );
}
